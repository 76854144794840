import React from 'react';
import './GuideCard.scss';

function GuideCard(props){
  return(
    <>
      <div
        className={
          props.id % 2 != 0 ?
            'guideCard':
            'guideCard guideRow'
        }
      >
        <div className="guideCard__textCont">
          <h2 className="guideCard__title">{props.name}</h2>
          <h3 className="guideCard__subtext">{props.text}</h3>
        </div>
        <div className="guideCard__imageCont">
          <img
            className={
              props.id % 2 != 0 ?
                "guideCard__image" :
                "guideCard__image guideCard__image--right"
            }
            src={props.img}
          />
        </div>
      </div>
    </>
  )
}

export default GuideCard;