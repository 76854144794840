import React from "react";
import { firestore } from "../../firebase.js";
import heroStaging from "../../Assets/images/staging_bg.png";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import GeneralText from "../../Components/GeneralText/GeneralText";
import EmailSignup from "../../Components/EmailSignup/EmailSignup.js";
import StagingAfter from "../../Assets/images/staging_after.png";
// import stagingTips from '../../Assets/images/staging_tips_bg.png';
// import stagingTipsTwo from '../../Assets/images/staging_tips_bg2.png';

import stagingDownload from "../../Assets/pdfs/StagingGuide-CAD.pdf";
import "./staging.scss";
import ConvertKitForm from "convertkit-react";
import Modal from "react-modal";

function Staging() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const MY_FORM_ID = 4925183;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "black",
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Guide Modal"
        style={customStyles}
      >
        <form
          className="downloadSellers__form"
          target="_blank"
          action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
          method="POST"
        >
          <input
            type="hidden"
            name="_subject"
            value={"Newsletter Signup: Staging Guide Download"}
          ></input>
          <input type="hidden" name="_captcha" value="false"></input>
          <input
            type="hidden"
            name="_next"
            value="https://www.rahmatyrealty.com/thankyou"
          ></input>
          <label className="downloadSellers__label">
            Subscribe to our newsletter for our guides and updates!
          </label>
          <input
            type="text"
            className="downloadSellers__input"
            name="Email"
            placeholder="Email"
          />
          <button type="submit" className="downloadSellers__formbutton">
            Submit
          </button>
        </form>
      </Modal>
      <section className="staging">
        <img src={heroStaging} className="staging__image" alt="" />
        <div className="staging__contentbox">
          <h3 className="staging__subtitle">Home Staging</h3>
          <div className="staging__buttonbox"></div>
        </div>
      </section>
      <GeneralText
        title="Why stage your home?"
        text="If you can visualize it, you can actualize it. Staging your home is both critical and beneficial to the hiring process. Staging while selling enhances buyer appeal, increases the chances of selling quickly and affects bidding price. How buyer’s see your home has a significant impact on their decision to put in an offer. To stage your home is to enhance and showcase its best features to attract buyers. Above all, it is about highlighting the focal points in the home and allowing prospective buyers to visualize potential ownership."
      />
      <div className="staging--greybg">
        <GeneralText
          title="Staging your home with Rahmaty Realty"
          text="Every room in your home should have a very specific impact on your potential buyer. The way you present it is what will drive a healthy offer, and we at Rahmaty Realty know exactly how to create those feelings"
          textTwo="To give you a clear image of what it is we mean, we’ve developed a room by room staging process with tips and common challenges we see often. Follow the guide, and see your home get snatched off the market in no time."
        />
      </div>
      <div className="staging__buttonbox">
        <button
          onClick={openModal}
          className="staging__button staging--redbutton"
        >
          Download staging guide
        </button>
      </div>
      <div className="staging__carousel">
        <Carousel showStatus={false} showThumbs={false}>
          <div>
            <img src={StagingAfter} />
          </div>
          <div>
            <img src={StagingAfter} />
          </div>
          <div>
            <img src={StagingAfter} />
          </div>
        </Carousel>
      </div>
      <div className="staging__buttonbox">
        <Link to="/#contact">
          <button className="staging__button staging--bluebutton">
            Get in touch
          </button>
        </Link>
      </div>
      <div className="staging__tips">
        <div className="staging__tipscontent">
          <h3 className="staging__tipstitle">Staging tips</h3>
          <div className="staging__tipsline" />
          <p className="staging__tipstext">
            1. Keep your home clean! Consider hiring a professional cleaning
            service
          </p>
          <p className="staging__tipstext">
            2. Declutter! and make everything look organized.
          </p>
          <p className="staging__tipstext">
            3. Remove personal photographs from walls and tabletops
          </p>
          <p className="staging__tipstext">
            4. Remove oversized and excess furniture and area rugs
          </p>
          <p className="staging__tipstext">
            5. Keep closets neat and tidy! Prospective buyers will want to
            evaluate the home's storage capacity.
          </p>
          <p className="staging__tipstext">
            6. Let the sunlight in bhy opening blinds and curtains
          </p>
          <p className="staging__tipstext">
            7. Replace burned-out, dim or flickering light bulbs
          </p>
          <p className="staging__tipstext">
            8. Consider replacing dated light fixtures
          </p>
          <p className="staging__tipstext">
            9. Paint! A fresh coat of paint will help renew the look and feel of
            any room. Choose 2 to 3 different neutrals to use throughout the
            entire house
          </p>
          <p className="staging__tipstext">
            10. Don't forget the exterior! Make it look as clean and presentable
            as the interior
          </p>
        </div>
      </div>
      <div className="staging__carousel">
        <Carousel showStatus={false} showThumbs={false}>
          <div>
            <img src={StagingAfter} />
          </div>
          <div>
            <img src={StagingAfter} />
          </div>
          <div>
            <img src={StagingAfter} />
          </div>
        </Carousel>
      </div>
      <div className="staging__buttonbox">
        <Link to="#">
          <button className="staging__button staging--bluebutton">
            Get in touch
          </button>
        </Link>
      </div>
      <div className="staging__tips">
        <div className="staging__tipscontent staging--kitchen">
          <h3 className="staging__tipstitle">Give your kitchen some love</h3>
          <div className="staging__tipsline" />
          <p className="staging__tiptext staging--tipssubtext">
            Give your kitchen some love
          </p>
          <p className="staging__tiptext staging--tipssubtext">
            The kitchen is the heart of your home and an area buyers will
            naturally quickly navigate to.This is your chance to make a
            memorable impression by ensuring that is clean, well-lit, and it
            should feel warm and welcoming interior
          </p>
        </div>
      </div>
      <EmailSignup theme="themeOne" />
    </>
  );
}

export default Staging;
