import React from 'react';
import { firestore } from '../../firebase.js';
import { Link } from 'react-router-dom';

import BlogCards from '../../Components/BlogCards/BlogCards';

import './blog.scss';
function Blog(){

    const [blogs,setBlogs] = React.useState([]);
    React.useEffect(() => {
        getBlogs();
      }, []); 
      const getBlogs = () => {
        firestore.collection('blog').orderBy('date', 'desc').get()
          .then(res => {
            let blogData = [];
            let allBlogs = res.docs;
            
            allBlogs.forEach(blog => {
                blogData.push(blog.data());
            });
            setBlogs(blogData);
          })
          .catch(err => {
            console.log(err);
          })
      }

      

      const convertDate = (date) =>{
          let dateString = Date(date).toString();
          let dateArray = dateString.split(' ');
          return dateArray[1] + " " +dateArray[3]
      }

      const renderBlogs = (blogs) => {
        let renderedBlogs = blogs.map(blog => {
          return (
            <BlogCards
              image={blog.thumbnail}
              title={blog.title}
              excerpt={blog.textOne}
              date={convertDate(blog.date)}
              link ={blog.id}
              id ={blog.title}
            />
          )
        });
        console.log(blogs)
        return renderedBlogs;
    }
    
    return (
      
        <>
        <section className='blog'>
           <div className='blog__list'>
               {renderBlogs(blogs)}
           </div>
        </section>
        </>
       );
}


export default Blog;