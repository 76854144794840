import { firestore } from "../../firebase.js";
import "./ContactForm.scss";

function ContactForm() {
  return (
    <section className="contactform">
      <h2 className="contactform__title">Contact us</h2>
      <form
        className="contactform__form"
        target="_blank"
        action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
        method="POST"
      >
        <input
          type="hidden"
          name="_subject"
          value="Contact Form: New message!"
        ></input>
        <input type="hidden" name="_captcha" value="false"></input>
        {/* <input
          type="hidden"
          name="_next"
          value="https://rahmatyrealty.com"
        ></input> */}
        <label className="contactform__label" htmlFor="name">
          Enter your name *
        </label>
        <input
          className="contactform__textinput"
          type="text"
          name="Name"
          required={true}
        />
        <label className="contactform__label" htmlFor="email">
          Enter your email *
        </label>
        <input
          className="contactform__textinput"
          type="email"
          name="Email"
          required={true}
        />
        <label className="contactform__label" htmlFor="message">
          Enter your message *
        </label>
        <textarea
          className="contactform__textarea"
          name="Message"
          required={true}
        ></textarea>
        <button className="contactform__button" type="submit">
          SUBMIT
        </button>
      </form>
    </section>
  );
}

export default ContactForm;
// const submitHandler = (e) => {
//   e.preventDefault();
//   firestore
//     .collection("mail")
//     .doc()
//     .set({
//       to: [`oali@atreomedia.com`],
//       message: {
//         html: `
//         <h3>Name: ${e.target.name.value}</h3>
//         <h3>Email: ${e.target.email.value}</h3>
//         <h3>Message:</h3>
//         <p>${e.target.message.value}</p>
//       `,
//         subject: "IMPORTANT: Consultation Call (Inbound Inquiry: Website)",
//         text: `
//         Name: ${e.target.name.value}
//         Email: ${e.target.email.value}
//         Message:
//         ${e.target.message.value}
//       `,
//       },
//     })
//     .then((res) => {
//       console.log("Success");
//       e.target.reset();
//       window.alert("Submitted!");
//     })
//     .catch((err) => {
//       console.log("err");
//       console.log(err);
//     });
// };
