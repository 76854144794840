import "./App.css";
import "./partials/_common.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./Helpers/ScrollToTop";
import Home from "./Pages/Home/home";
import About from "./Pages/About/about";
import SellersGuide from "./Pages/Sellers/sellers";
import BuyersGuide from "./Pages/Buyers/buyers";
import Staging from "./Pages/Staging/staging";
import HomeEvaluation from "./Pages/HomeEvaluation/homeevaluation";
import Blog from "./Pages/Blog/blog";
import BlogPage from "./Pages/BlogPage/blogpage";
import FeaturedListings from "./Pages/FeaturedListings/FeaturedListings";

import FeaturedProperty from "./Pages/FeaturedProperty/FeaturedProperty";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import City from "./Pages/City/City";
import Property from "./Pages/Property/Property";
import ThankYou from "./Pages/ThankYou/ThankYou";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/sellersguide" component={SellersGuide} />
        <Route path="/buyersguide" component={BuyersGuide} />
        <Route path="/staging" component={Staging} />
        <Route path="/homeevaluation" component={HomeEvaluation} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:id" component={BlogPage} />
        {/* <Route path='/ontario' component={Ontario} /> */}
        {/* <Route path='/listings/:city' component={City} /> */}
        <Route path="/listings" exact component={FeaturedListings} />
        <Route path="/listings/property/:id" component={FeaturedProperty} />
        <Route path="/property/:property" component={Property} />
        <Route path="/thankyou" component={ThankYou} />
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
