import { Link } from "react-router-dom";
import "./FeaturedRow.scss";

function FeaturedRow(props) {
  return (
    <section
      className={
        props.inverse ? "featuredrow featuredrow--inverse" : "featuredrow"
      }
    >
      <div className="featuredrow__carousel">
        <img className="featuredrow__image" src={props.image} alt="" />
      </div>
      <div
        className={
          props.inverse
            ? "featuredrow__textbox featuredrow__textbox--inverse"
            : "featuredrow__textbox"
        }
      >
        <h3 className="featuredrow__title">{props.address}</h3>
        <h4 className="featuredrow__price">${props.price}</h4>
        <p className="featuredrow__text">{props.description}</p>
        <button className="featuredrow__button">
          <Link to={`/listings/property/${props.id}`}>View listing</Link>
        </button>
      </div>
    </section>
  );
}

export default FeaturedRow;
