import { Link } from 'react-router-dom';
import React from 'react';
import './EvaluationBanner.scss'
function EvaluationBanner(){
    return(
        <>
        <section className="evaluationBanner">
            <div className='evaluationBanner__textbox'>
                <h3 className='evaluationBanner__title'>What's your home worth?</h3>
                <p className='evaluationBanner__text'>Get a FREE home evaluation</p>
            </div>
            <div className="evaluationBanner__buttoncont">
                <h1 className="evaluationBanner_input"></h1>
                <Link to='/staging'>
                    <button className='evaluationBanner__button' type='submit'>Learn more</button>
                </Link> 
            </div>
        </section>
        </>
    )
}

export default EvaluationBanner;