import { firestore } from "../../firebase.js";
import "./DownloadSellers.scss";
import buyersGuide from "../../Assets/pdfs/REMAX-Buyers-Guide.pdf";
import sellersGuide from "../../Assets/pdfs/REMAX-Sellers-Guide.pdf";
import ConvertKitForm from "convertkit-react";
import React from "react";
import Modal from "react-modal";

function DownloadSellers(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const MY_FORM_ID_BUYERS = 4923735;
  const MY_FORM_ID_SELLERS = 4925164;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "black",
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="downloadSellers">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Guide Modal"
        style={customStyles}
      >
        <form
          className="downloadSellers__form"
          target="_blank"
          action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
          method="POST"
        >
          <input
            type="hidden"
            name="_subject"
            value={
              props.type === "buyers"
                ? "Newsletter Signup: Buyers Guide Download"
                : "Newsletter Signup: Sellers Guide Download"
            }
          ></input>
          <input type="hidden" name="_captcha" value="false"></input>
          <input
            type="hidden"
            name="_next"
            value="https://www.rahmatyrealty.com/thankyou"
          ></input>
          <label className="downloadSellers__label">
            Subscribe to our newsletter for our guides and updates!
          </label>
          <input
            type="text"
            className="downloadSellers__input"
            name="Email"
            placeholder="Email"
          />
          <button type="submit" className="downloadSellers__formbutton">
            Submit
          </button>
        </form>
      </Modal>
      <div className="downloadSellers__textbox">
        {props.type === "buyers" ? (
          <>
            <h3 className="downloadSellers__title">Download buyers guide</h3>
            <h4 className="downloadSellers__subtitle">
              Wondering what comes next?<br></br>Sign up for our newsltter to
              download our FREE buyers guide
            </h4>
          </>
        ) : (
          <>
            <h3 className="downloadSellers__title">Download sellers guide</h3>
            <h4 className="downloadSellers__subtitle">
              Wondering what comes next?<br></br>Sign up for our newsltter to
              download our FREE sellers guide
            </h4>
          </>
        )}
      </div>
      <div className="downloadSellers__form">
        {props.type === "buyers" ? (
          <button
            type="submit"
            className="downloadSellers__button"
            onClick={openModal}
          >
            Download
          </button>
        ) : (
          <button
            type="submit"
            className="downloadSellers__button"
            onClick={openModal}
          >
            Download
          </button>
        )}
      </div>
    </section>
  );
}

export default DownloadSellers;
