import React from 'react';
import { firestore } from '../../firebase.js';

import CarouselGeneral from '../../Components/CarouselGeneral/CarouselGeneral';
import './blogpage.scss'
function BlogPage(props){
  const [blog, setBlog] = React.useState([]);
  const [listings,setListings] = React.useState([]);

  React.useEffect(() => {
    getBlog(props.match.params.id);
  },[props.match.params.id]);


  React.useEffect(() => {
    getListings();
  }, []);

  const getBlog = (id) =>{
    firestore.collection('blog').doc(id).get()
    .then(res => {
      let blogDetail = res.data();
      setBlog(blogDetail);
    })
  }
  
  const getListings = () => {
    firestore.collection('featuredproperty').get()
      .then(res => {
        let listingData = [];
        let allListings = res.docs;   
        allListings.forEach(listing => {
           listingData.push(listing.data());
        });
        setListings(listingData);
      })
      .catch(err => {
        console.log(err);
      })
    }
    
    const convertDate = (date) =>{
      let dateString = Date(date).toString();
      let dateArray = dateString.split(' ');
      return dateArray[1] + " " +dateArray[3]
    }

    return(
    <>
    <img className="heroimage"src={blog.heroimg}></img>
    <div className="blogpage">
      <h2 className="blogpage__title">{blog.title}</h2>
      
      <p className="blogpage__text" dangerouslySetInnerHTML={{ __html: blog.textOne }}></p>
      <img className='blogpage__image imgCentre'src={blog.imgOne}></img>
      <p className="blogpage__text" dangerouslySetInnerHTML={{ __html: blog.textTwo }}></p>
      <img className="blogpage__image imgCentre" src={blog.imgTwo}></img>
    </div>
    {/* <CarouselGeneral
        title='Our featured listings'
        images={listings}
        linkSource={'property'}
        theme='singleCaro'
        /> */}
     
    </>
    )
}


export default BlogPage;