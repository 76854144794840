import Carousel, { consts } from 'react-elastic-carousel';
import icnLeft from '../../Assets/icons/icn_leftArrow.svg';
import icnRight from '../../Assets/icons/icn_rightArrow.svg';

import GeneralImgandText from '../../Components/GeneralImgandText/GeneralImgandText';
import TLN from '../../Assets/images/the_latest_numbers.png'

import './AboutCarsousel.scss';

function AboutCarousel(props){
    let breakPoints = []
      breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1100, itemsToShow: 1 },
      ];
  
  
  
  
    const myArrow = ({ type, onClick, isEdge }) => {
      let pointer = (type === consts.PREV) ? icnLeft : icnRight;
      return (
        <button
          className='aboutCarousel__button'
          onClick={onClick}
          disabled={isEdge}
        >
          <img className='aboutCarousel__buttonimage' src={pointer} alt="" />
        </button>
      )
    }

  return(
  <>
  <section className='aboutCarousel'>
    <Carousel 
    breakPoints={breakPoints}
    renderArrow={myArrow}
    pagination={false}
    className='aboutCarousel__carousel'>
        <GeneralImgandText
            image={TLN}
            title="The lastest numbers"
            text="The real estate market is always changing. This is why we ensure to do the research for you. Wondering what to expect on your next transaction? Check out the latest numbers here"
            rowReverse = {true}
        />
        <GeneralImgandText
            image={TLN}
            title="The lastest numbers"
            text="The real estate market is always changing. This is why we ensure to do the research for you. Wondering what to expect on your next transaction? Check out the latest numbers here"
            rowReverse = {true}
        />
        <GeneralImgandText
            image={TLN}
            title="The lastest numbers"
            text="The real estate market is always changing. This is why we ensure to do the research for you. Wondering what to expect on your next transaction? Check out the latest numbers here"
            rowReverse = {true}
        />
    </Carousel>
    </section>
  </>
  )
}

export default AboutCarousel;