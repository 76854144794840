import React from 'react';
import { firestore } from '../../firebase.js';
import Village from '../../Assets/images/about_img1.png'
import CityCard from '../../Components/CityCard/CityCard'
import LocalPicksCards from '../../Components/LocalPicksCards/LocalPicksCards'
import GeneralText from '../../Components/GeneralText/GeneralText'
import EmailSignup from '../../Components/EmailSignup/EmailSignup'
import CarouselGeneral from '../../Components/CarouselGeneral/CarouselGeneral.js';
import aboutHero from '../../Assets/images/about_hero.png';
import propertyPlaceholder from '../../Assets/images/property_placeholder.png';
import './about.scss';

function About() {
  const [cities, setCities] = React.useState([]);
  const [localPicks, setLocalPicks] = React.useState([]);
  const [listings, setListings] = React.useState([]);

  React.useEffect(() => {
    getCities();
  }, []);

  React.useEffect(() => {
    getLocalPicks();
  }, []);

  React.useEffect(() => {
    getListings();
  }, []);

  const getCities = () => {
    firestore.collection('aboutCities').orderBy('id').get()
      .then(res => {
        let cityData = [];
        let allCities = res.docs;

        allCities.forEach(city => {
          cityData.push(city.data());
        });
        setCities(cityData);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getLocalPicks = () => {
    firestore.collection('localPicks').get()
      .then(res => {
        let localPickData = [];
        let allLocalPicks = res.docs;

        allLocalPicks.forEach(localpick => {
          localPickData.push(localpick.data());
        });
        setLocalPicks(localPickData);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getListings = () => {
    firestore.collection('featuredproperty').get()
      .then(res => {
        let listingData = [];
        let allListings = res.docs;   
        allListings.forEach(listing => {
            listingData.push(listing.data());
        });
        setListings(listingData);
      })
      .catch(err => {
        console.log(err);
      })
    }

  const renderCities = (cities) => {
    let renderedCities = cities.map(city => {
      return (
        <CityCard
          img={city.img}
          name={city.name}
          text={city.text}
          id={city.id}
        />
      )
    });
    return renderedCities;
  }

  const renderLocalPicks = (localpicks) => {
    let renderedLocalPicks = localpicks.map(localpick => {
      return (
        <LocalPicksCards
          img={localpick.img}
          name={localpick.name}
          location={localpick.location}
          text={localpick.text}
          link={localpick.link}
        />
      )
    });
    return renderedLocalPicks;
  }
  // console.log(listings[0].image)

  return (
    <>
      <section className="about">
        <img src={aboutHero} alt="hero image of the about page" className="about__hero" />
      </section>
      <div className='citybox'>
        {renderCities(cities)}
      </div>
      <GeneralText
        title="Our local favourites"
        text="Looking to explore the city? Check out some of our favourite spots in the city. From coffee shops to retail locations, these businesses are what make Guelph so special"
      />
      <section className='cardbox'>
        <div className='cardbox__list'>
          {renderLocalPicks(localPicks)}
        </div>
      </section>
      {/* <CarouselGeneral
        title='Our featured listings'
        images={listings}
        linkSource={'property'}
        theme='singleCaro'
        /> */}
      <EmailSignup
        theme='themeOne'
      />
    </>
  );
}


export default About;