import React from "react";
import { firestore } from "../../firebase.js";
import "./HomeForm.scss";

import CarouselGeneral from "../../Components/CarouselGeneral/CarouselGeneral";
import EmailSignup from "../../Components/EmailSignup/EmailSignup.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HomeForm() {
  const [listings, setListings] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());
  React.useEffect(() => {
    getListings();
  }, []);

  const getListings = () => {
    firestore
      .collection("featuredproperty")
      .get()
      .then((res) => {
        let listingData = [];
        let allListings = res.docs;
        allListings.forEach((listing) => {
          listingData.push(listing.data());
        });
        setListings(listingData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    firestore
      .collection("mail")
      .doc()
      .set({
        to: [`oali@atreomedia.com`],
        message: {
          html: `
            <h3>Name: ${e.target.name.value}</h3>
            <h3>Email: ${e.target.email.value}</h3>
            <h3>Phone number: ${e.target.phone.value}</h3>
            <h3>Address: ${e.target.address.value}</h3>
            <h3>Are you currently working with a real estate agent?: ${e.target.date.value}</h3>
            <h3>Date: ${startDate}</h3>
            <h3>Message: ${e.target.message.value}</h3>
            `,
          subject: "IMPORTANT: Home Evaluation",
          text: `
            Name: ${e.target.name.value}
            Email: ${e.target.email.value}
            Phone number: ${e.target.phone.value}
            Address: ${e.target.date.value}
            Are you currently working with a real estate agent?: ${e.target.address.value}
            Date: ${startDate}
            Message: ${e.target.message.value}
            `,
        },
      })
      .then((res) => {
        console.log("Success");
        e.target.reset();
        window.alert("Submitted!");
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  };

  return (
    <>
      <section className="homeform">
        <h2 className="homeform__title">Enter your information below</h2>
        <form
          className="homeform__form"
          target="_blank"
          action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
          method="POST"
        >
          <input
            type="hidden"
            name="_subject"
            value="Home Evaluation: New submission!"
          ></input>
          <input type="hidden" name="_captcha" value="false"></input>
          {/* <input
            type="hidden"
            name="_next"
            value="https://rahmatyrealty.com"
          ></input> */}
          <label className="homeform__label" htmlFor="name">
            Your name*
          </label>
          <input
            className="homeform__textinput"
            type="text"
            name="Name"
            required={true}
          />
          <label className="homeform__label" htmlFor="email">
            Your email*
          </label>
          <input
            className="homeform__textinput"
            type="email"
            name="Email"
            required={true}
          />
          <label className="homeform__label" htmlFor="phone">
            Your phone number*
          </label>
          <input
            className="homeform__textinput"
            type="text"
            name="Phone"
            required={true}
          />
          <label className="homeform__label" htmlFor="address">
            Your property address*
          </label>
          <input
            className="homeform__textinput"
            type="text"
            name="Address"
            required={true}
          />
          <label className="homeform__label" htmlFor="date">
            When are you thinking of moving?*
          </label>
          <DatePicker
            className="homeform__textinput"
            selected={startDate}
            type="text"
            name="Date"
            onChange={(date) => setStartDate(date)}
          />
          <label className="homeform__label" htmlFor="date">
            Are you currently working with a real estate agent?
          </label>
          <select
            type="text"
            name="Are you currently working with a real estate agent?"
            className="homeform__select"
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          <label className="homeform__label" htmlFor="message">
            Is there anything else you would like us to know?
          </label>
          <textarea
            className="homeform__textarea"
            name="Is there anything else you would like us to know?"
            required={true}
          ></textarea>
          <button className="homeform__button" type="submit">
            SUBMIT
          </button>
        </form>
      </section>
      {/* <CarouselGeneral
    title='Our featured listings'
    images={listings}
    linkSource={'property'}
    theme='singleCaro'
    /> */}
      <EmailSignup theme="themeOne" />
    </>
  );
}

export default HomeForm;
