import React from "react";
import GeneralText from "../../Components/GeneralText/GeneralText";
import "./ThankYou.scss";
import BuyersImage from "../../Assets/images/BuyersImage.png";
import SellersImage from "../../Assets/images/SellersImage.png";
import StagingImage from "../../Assets/images/StagingImage.png";
import BuyersGuide from "../../Assets/pdfs/REMAX-Buyers-Guide.pdf";
import SellersGuide from "../../Assets/pdfs/REMAX-Sellers-Guide.pdf";
import StagingGuide from "../../Assets/pdfs/StagingGuide-CAD.pdf";
function ThankYou() {
  return (
    <>
      <GeneralText
        title=" Thank You for Subscribing to Our Newsletter!"
        text="We appreciate your interest in our content and look forward to keeping you updated on our latest news. As a token of our appreciation, please select any of our guides below."
      />
      <div className="thankyou">
        <div className="thankyou__item">
          <a href={BuyersGuide}>
            <img className="thankyou__image" src={BuyersImage} />
          </a>
        </div>
        <div className="thankyou__item">
          <a href={SellersGuide}>
            <img className="thankyou__image" src={SellersImage} />
          </a>
        </div>
        <div className="thankyou__item">
          <a href={StagingGuide}>
            <img className="thankyou__image" src={StagingImage} />
          </a>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
