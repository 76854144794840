import { firestore } from "../../firebase.js";
import "./RequestForm.scss";

function RequestForm(props) {
  const title = props.address + ": Info Requested";
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   firestore.collection('mail').doc().set({
  //     to: [`jyou@atreomedia.com`],
  //     message: {
  //       html: `
  //         <h3>Name: ${e.target.name.value}</h3>
  //         <h3>Email: ${e.target.email.value}</h3>
  //         <h3>Message:</h3>
  //         <p>${e.target.message.value}</p>
  //       `,
  //       subject: 'IMPORTANT: Consultation Call (Inbound Inquiry: Website)',
  //       text: `
  //         Name: ${e.target.name.value}
  //         Email: ${e.target.email.value}
  //         Message:
  //         ${e.target.message.value}
  //       `,
  //     }
  //   })
  //     .then(res => {
  //       console.log('Success');
  //       e.target.reset();
  //       window.alert('Submitted!');
  //     })
  //     .catch(err => {
  //       console.log('err');
  //       console.log(err);
  //     });
  // }
  return (
    <section className="requestform">
      <div className="requestform__infobox">
        <h4 className="requestform__title">Request more info or a showing</h4>
        <h5 className="requestform__subtitle">Rahmaty Realty</h5>
        <p className="requestform__text">Phone: (519)-242-5577</p>
        <p className="requestform__text">Email: info@rahmatyrealty.com</p>
      </div>
      <form
        className="requestform__form"
        target="_blank"
        action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
        method="POST"
      >
        {" "}
        <input type="hidden" name="_subject" value={title}></input>
        <input type="hidden" name="_captcha" value="false"></input>
        {/* <input
          type="hidden"
          name="_next"
          value="https://rahmatyrealty.com"
        ></input> */}
        <input
          type="text"
          className="requestform__textinput"
          name="name"
          placeholder="Name"
        />
        <input
          type="email"
          className="requestform__textinput"
          name="email"
          placeholder="Email"
        />
        <textarea
          className="requestform__textarea"
          name="message"
          placeholder="Message"
        ></textarea>
        <button className="requestform__button" type="submit">
          SUBMIT
        </button>
      </form>
    </section>
  );
}

export default RequestForm;
