import { Link } from 'react-router-dom';
import './BlogCards.scss';

function BlogCard(props) {
  return (
    <Link
      to= {`/blog/${props.link}`}
      className='blogCard'
    >
          <img className="blogCard__image" src={props.image}/>
          <h2 className="blogCard__title">{props.title}</h2>
          <h3 className="blogCard__excerpt" dangerouslySetInnerHTML={{ __html: props.excerpt }}></h3>
          <h3 className="blogCard__date">{props.date}</h3>
    </Link>
  );
}

export default BlogCard