import React from 'react';
import { firestore } from '../../firebase.js';
import DownloadSellers from '../../Components/DownloadSellers/DownloadSellers';
import ListingSignup from '../../Components/ListingSignup/ListingSignup';
import GeneralText from '../../Components/GeneralText/GeneralText';
import GuideCard from '../../Components/GuideCard/GuideCard';
import iconOne from '../../Assets/icons/icn_sellers1.svg';
import iconTwo from '../../Assets/icons/icn_buyers2.svg';
import iconThree from '../../Assets/icons/icn_buyers3.svg';
import iconFour from '../../Assets/icons/icn_buyers4.svg';
import iconFive from '../../Assets/icons/icn_buyers5.svg';
import iconSix from '../../Assets/icons/icn_buyers6.svg';
import iconSeven from '../../Assets/icons/icn_sellers7.svg';
import iconEight from '../../Assets/icons/icn_buyers8.svg';
import iconNine from '../../Assets/icons/icn_sellers9.svg';
import iconTen from '../../Assets/icons/icn_buyers10.svg';
import iconEleven from '../../Assets/icons/icn_buyers11.svg';
import buyersHero  from '../../Assets/images/buyers_hero.png';
import './buyers.scss';

function buyers(){
  const guideParts = [
    {
      title:"Decide to sell your home",
      text: "Make sure you are ready both financially and emotionally",
      img:iconOne,
      id: 1
    },
    {
      title:"Get pre-approved",
      text:"Work with a mortgage broker or your bank. They will work with you on what you require to submit an application. Once approved, this will determine how much you can afford to spend on a new home",
      img: iconTwo,
      id: 2
    },
    {
      title:"Realtor consultation",
      text:"Work with a us to help guide you through the process. The right agent will discuss your price range, ideal locations, current market conditions and much more!",
      img: iconThree,
      id: 3
    },
    {
      title:"Start your search",
      text:"We will get you information on new homes that meet your criteria as soon as they’re listed. They’ll work with you and for you to ensure you find your dream home.",
      img: iconFour,
      id: 4
    },
    {
      title:"Current market conditions",
      text:"We are a valuable resource as you consider different properties. They will be there when you have questions regarding the homes you’re interested – they can tell you what is a good deal… and when to walk away.",
      img: iconFive,
      id: 5
    },
    {
      title:"Make an offer",
      text:"We will help create your offer tailored to your needs including the right subject clauses down to the closing date that best works for you",
      img: iconSix,
      id: 6
    },
    {
      title:"Negotiate",
      text:"You may receive a counter offer but don’t be worried! We will negotiate for you to ensure you get the best possible price for the house you love",
      img: iconSeven,
      id: 7
    },
    {
      title:"Accepted offer",
      text:"It’s crunch time! The next few weeks are busy as you need to schedule and remove every one of your subject clauses by the specified date. You’ll likely need to schedule an inspection, appraisal, financing approval, and several others. You will also need to provide a deposit to put down on the home. The deposit will be a pre-determined amount given in-trust to us to show the sellers you are committed to this home",
      img: iconEight,
      id: 8
    },
    {
      title:"Subject removal",
      text:"Once you have completed all your subject clauses, and everything went smooth, it is time for you to sign on the dotted line and consider your new home to be yours (almost!).",
      img: iconNine,
      id: 9
    },
    {
      title:"Official documents",
      text:"You will need to provide your us with your preferred lawyer or notary to have the official title transferred into your name. You will meet with the lawyer or notary in person to sign all the legal documents before you move in. This typically happens a few days before you take possession of your new home.",
      img: iconTen,
      id: 10
    },
    {
      title:"Move in!",
      text:"Congratulations, you are officially a homeowner! The date pre-determined by you is move-in day! You can now move into your new home",
      img: iconEleven,
      id: 11
    },
  ];

  const renderGuide = (guides) => {
    let renderedGuides = guides.map(guide => {
      return (
        <GuideCard
          name={guide.title}
          text={guide.text}
          id={guide.id}
          img={guide.img}
        />
      )
    });
    return renderedGuides;
  }

  return(
  <>
    <section className='hero'>
      <img className='hero__image' src={buyersHero} alt="" />
    </section>
    <GeneralText
      title="Buying a home with Rahmaty Realty"
      text="Price is what you pay but value is what you get. We pride ourselves in matching the right buyer with the right home at the right value. Buying a home is a personal decision and we strive to make this experience an extraordinary one for each of our customers. In this home buying journey we help you in every step of the way. With our advanced technology, knowledge  and expertise in the real estate field. We leave no stone unturned to ensure you find your dream home"
      row={true}
    />
    <DownloadSellers
      type='buyers'
    />
    <section className='guidebox'>
      <div className='guidebox__list'>
        {renderGuide(guideParts)}
      </div>
    </section>
    <ListingSignup />
  </>
  )
}

export default buyers;