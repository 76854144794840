import './GeneralImgandText.scss';

function GeneralImgandText(props) {
  return (
    
    <section
      className={
        props.buttons ?
          'generalimgandtext generalimgandtext--withbutton' :
          props.notop !== true ?
            'generalimgandtext' :
            'generalimgandtext generalimgandtext--notop'
        }>     
      <div className={
        props.rowReverse === true ?
        'generalimgandtext__content generalimgandtext--rowReverse' :
        'generalimgandtext__content'
        }>
        <div className={'generalimgandtext__imgcontainer'}>
          <img className={'generalimgandtext__image'} src={props.image}></img>
        </div>
        <div className={'generalimgandtext__textcontainer'}>
        <h3 className='generalimgandtext__title'>{props.title}</h3>
        <p className='generalimgandtext__text'>{props.text}</p>
        { props.buttonlink ?
          <div className='generalimgandtext__buttonbox'>
            <a className='generalimgandtext__link' href={props.buttonlink}>
              <button className='generalimgandtext__button'>{props.buttontext}</button>
            </a>
          </div> :
          <></>
        }
        </div>
      </div>
    </section>
  );
}

export default GeneralImgandText;