import { Link } from 'react-router-dom';
import './LocalPicksCards.scss';

function LocalPicksCards(props) {
  return (
    <>
      <div className="localPicksCard">
        <img className="localPicksCard__image" src={props.img} />
        <h2 className="localPicksCard__title">{props.name}</h2>
        <h3 className="localPicksCard__location">{props.location}</h3>
        <h3 className="localPicksCard__text">{props.text}</h3>
        <div className='localPicksCard__buttonbox'>
          <Link to='https://www.youtube.com' target="_blank" rel="noopener noreferrer" >
            <button className='localPicksCard__button'>Learn more</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default LocalPicksCards