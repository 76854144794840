import React from "react";
import { firestore } from "../../firebase.js";
import { Link } from "react-router-dom";

import GeneralImgandText from "../../Components/GeneralImgandText/GeneralImgandText";
import AboutCarousel from "../../Components/AboutCarsousel/AboutCarsousel.js";
import EvaluationBanner from "../../Components/EvaluationBanner/EvaluationBanner.js";
import GeneralText from "../../Components/GeneralText/GeneralText";
import EmailSignup from "../../Components/EmailSignup/EmailSignup.js";
import MortCalc from "../../Components/MortgageCalculatorAdjustable/MortgageCalculatorAdjustable";
import Testimonal from "../../Components/Testimonial/Testimonial";
import CarouselGeneral from "../../Components/CarouselGeneral/CarouselGeneral";
import ContactForm from "../../Components/ContactForm/ContactForm.js";
import ListingSignup from "../../Components/ListingSignup/ListingSignup";

import MLSCaro from "../../Components/MLSCaro/MLSCaro.js";

import heroHome from "../../Assets/images/home_hero.png";
import heroHomeSubmark from "../../Assets/images/logo_submark_rr.svg";
import Shikeeb from "../../Assets/images/shikeeb_rahmaty.png";
import TestimonialPlaceholder from "../../Assets/images/testimonial_placeholder.png";

import "./home.scss";
function Home() {
  const [blogs, setBlogs] = React.useState([]);
  const [listings, setListings] = React.useState([]);

  React.useEffect(() => {
    getBlogs();
  }, []);

  React.useEffect(() => {
    getListings();
  }, []);

  const getBlogs = () => {
    firestore
      .collection("blog")
      .orderBy("date", "desc")
      .get()
      .then((res) => {
        let blogData = [];
        let allBlogs = res.docs;
        allBlogs.forEach((blog) => {
          blogData.push(blog.data());
        });
        setBlogs(blogData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getListings = () => {
    firestore
      .collection("featuredproperty")
      .get()
      .then((res) => {
        let listingData = [];
        let allListings = res.docs;
        allListings.forEach((listing) => {
          listingData.push(listing.data());
        });
        setListings(listingData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="herohome">
        <img src={heroHome} className="herohome__image" alt="" />
        <div className="herohome__contentbox">
          <img src={heroHomeSubmark} className="herohome__logo" alt="" />
          <h3 className="herohome__subtitles">Your real estate expert</h3>
          <div className="herohome__buttonbox">
            <Link to="/listings">
              <button className="herohome__button">View listings</button>
            </Link>
          </div>
        </div>
      </section>
      <h3 className="home__sectiontitle">About us</h3>
      <GeneralImgandText
        image={Shikeeb}
        title="Shikeeb Rahmaty"
        text="With over eight years of experience as a real estate agent specializing in first-time buyers and renovation projects. He has lived his entire life in the Guelph area which, in addition to his education from OREA college, has added to his expertise within the real estate industry."
        buttonlink="/listings"
        buttontext="View listings"
      />
      <AboutCarousel />
      <EvaluationBanner />
      {/* <CarouselGeneral
        title='Our featured listings'
        images={listings}
        linkSource={'property'}
        theme='singleCaro'
        /> */}

      <CarouselGeneral
        title="Our latest blog posts"
        images={blogs}
        linkSource={"blog"}
      />

      <MortCalc
        title="Mortgage Calculator"
        text="Ready to buy? Check out your estimated monthly costs with our easy-to-use mortgage calculator"
        adjustable={true}
      />
      {/* <Testimonal
        image={TestimonialPlaceholder}
        title="Testimonial"
        text1="“Shikeeb Rahmaty is knowledgable, honest, trustworthy, and has no pressure approach. He listens to your needs so he understands what you are looking for and how it must fit into your lifestyle. I highly recommend Shikeeb as your realtor because he can accomplish your real estate dreams.” - Gulnoza Hamidullah"
        text2 = "This is a test"
        text3 = "This is another test"
        buttonlink="#"
        buttontext ="Book appointment"
        />         */}
      <ListingSignup />
      <ContactForm />
    </>
  );
}

export default Home;
