import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import logo from "../../Assets/images/logo_rr.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.scss";
import { NavDropdown } from "react-bootstrap";

function Header(props) {
  function showSettings(event) {
    event.preventDefault();
  }

  return (
    <>
      <Sidebar />
      <header className="header">
        <nav className="header__nav">
          <Link to="/" className="header__link--logo">
            <img className="header__logo" src={logo} alt="" />
          </Link>
          <div className="header__container">
            <Link to="/about" className="header__link">
              Guelph
            </Link>
            <NavDropdown
              className="header__link header__dropdown"
              title="Guides"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                className="header__link header__dropdown--link"
                href="/sellersguide"
              >
                Sellers guide
              </NavDropdown.Item>
              <NavDropdown.Item
                className="header__link header__dropdown--link"
                href="/buyersguide"
              >
                {" "}
                Buyers guide
              </NavDropdown.Item>
            </NavDropdown>

            <Link to="/homeevaluation" className="header__link">
              Home evaluation
            </Link>
            <Link to="/staging" className="header__link">
              Staging
            </Link>
            <Link to="/listings" className="header__link">
              Listings
            </Link>
            <Link to="/blog" className="header__link">
              Blog
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
