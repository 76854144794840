import React from 'react';

import './homeevaluation.scss';

import HomeEvaluationHero from '../../Assets/images/evaluation_hero.png';
import GeneralText from '../../Components/GeneralText/GeneralText';
import HomeForm from '../../Components/HomeForm/HomeForm';
function HomeEvaluation(){
    return(
    <>
    <section className='hero'>
        <img className='hero__image' src={HomeEvaluationHero} alt="" />
    </section>

    <GeneralText
        title = "Get a FREE home evaluation"
        text = "While home valuation tools look at millions of transactions to predict the worth of a home they often miss out on calculations based on crucial information making it inaccurate. It is more important than ever to price your home right which is why we have designed a free tool that provides you with a detailed evaluation by taking into account the market analysis and value of homes in the area"
    />
    <HomeForm/>
    
    </>
    )
}
export default HomeEvaluation;