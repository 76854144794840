import React from "react";
import { firestore } from "../../firebase.js";
import PropertyOverview from "../../Components/PropertyOverview/PropertyOverview";
import Amenities from "../../Components/Amenities/Amenities";
import PropertyDetail from "../../Components/PropertyDetail/PropertyDetail";
import MortgageCalculator from "../../Components/MortgageCalculator/MortgageCalculator";
import GeneralText from "../../Components/GeneralText/GeneralText";
import RequestForm from "../../Components/RequestForm/RequestForm";
import CarouselGeneral from "../../Components/CarouselGeneral/CarouselGeneral";
import ListingSignup from "../../Components/ListingSignup/ListingSignup";
import "./FeaturedProperty.scss";
import LightBoxCarousel from "../../Components/LightboxCarousel/LightBoxCarousel";

function FeaturedProperty(props) {
  const [property, setProperty] = React.useState({ price: 0 });
  const [relatedProperty, setRelatedProperty] = React.useState([]);
  const [province, setProvince] = React.useState("");

  React.useEffect(() => {
    getPropertyDetail(props.match.params.id);
  }, [props.match.params.id]);

  const getPropertyDetail = (id) => {
    let relatedListings = [];
    firestore
      .collection("featuredproperty")
      .get()
      .then((res) => {
        res.forEach((doc) => {
          let propertyDetail = doc.data();
          if (propertyDetail.id === id) {
            setProperty(propertyDetail);
            setProvince(propertyDetail.province);
          } else {
            relatedListings.push({
              image: propertyDetail.thumbnail,
              title: propertyDetail.address,
              price: propertyDetail.price,
              city: propertyDetail.city,
              beds: propertyDetail.beds,
              baths: propertyDetail.baths,
              sqft: propertyDetail.sqft,
              built: propertyDetail.built,
              link: "",
              page: "",
            });
          }
        });
        setRelatedProperty(relatedListings);
      })
      .catch((err) => {
        console.log(err);
      });
    // firestore.collection('featuredproperty').doc(id).get()
    //   .then(res => {
    //     let propertyDetail = res.data();
    //     setProperty(propertyDetail);
    //     setProvince(propertyDetail.province);
    //     if (propertyDetail.city === 'Kitchener' || propertyDetail.city === 'Waterloo') {
    //       getRelatedListings('kw');
    //     } else {
    //       getRelatedListings(propertyDetail.city.toLowerCase());
    //     }
    //     if (propertyDetail.city.toLowerCase() === 'hamilton') {
    //       setCityCoord({ lat: 43.255203, lng: -79.843826 });
    //     } else if (propertyDetail.city.toLowerCase() === 'milton') {
    //       setCityCoord({ lat: 43.526646, lng: -79.891205 });
    //     } else if (propertyDetail.city.toLowerCase() === 'burlington') {
    //       setCityCoord({ lat: 43.328674, lng: -79.817734 });
    //     } else if (propertyDetail.city.toLowerCase() === 'kitchener') {
    //       setCityCoord({ lat: 43.452969, lng: -80.495064 });
    //     } else if (propertyDetail.city.toLowerCase() === 'waterloo') {
    //       setCityCoord({ lat: 43.466667, lng: -80.516670 });
    //     } else if (propertyDetail.city.toLowerCase() === 'london') {
    //       setCityCoord({ lat: 42.983612, lng: -81.249725 });
    //     }
    //     let images = [];
    //     firestore.collection('localpicks').get()
    //       .then(res => {
    //         let localPicks = [];
    //         res.docs.forEach(doc => {
    //           if (propertyDetail.province === 'ON' && doc.data().province === 'ON') {
    //             localPicks.push({
    //               image: doc.data().img,
    //               link: doc.data().link,
    //               name: doc.data().name
    //             });
    //           } else if (propertyDetail.province === 'FL' && doc.data().province === 'FL') {
    //             localPicks.push({
    //               image: doc.data().img,
    //               link: doc.data().link,
    //               name: doc.data().name
    //             });
    //           };
    //         });
    //         setLocalpicks(localPicks);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       })
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
  };

  return (
    <>
      {property.imagearray ? (
        <>
          <section>
            <img
              className="heroproperty__img"
              src={property.imagearray[0]}
              alt="property images"
            />
            <div className="heroproperty__container">
              <p className="heroproperty__text">
                {property.imagearray
                  ? `1 out of ${property.imagearray.length}`
                  : ""}
              </p>
              <LightBoxCarousel imagesArr={property.imagearray} />
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      <PropertyOverview
        address={property.address}
        city={property.city}
        province={property.province}
        beds={property.beds}
        baths={property.baths}
        sqfeet={property.sqfeet}
        built={property.built}
        description={property.description}
        price={property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        page={"featured"}
      />
      <PropertyDetail
        address={property.address}
        price={
          "$" + property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        type={property?.type === undefined ? "N/A" : null}
        sqfeet={property.sqfeet}
        baths={property.bath}
        lot={property?.lot === undefined ? "N/A" : null}
        built={property.built}
        mls={property?.mls}
        broker={property?.broker}
        postalcode={property?.postalcode}
        // cityCoord={cityCoord}
        // propertyCoord={propertyCoord}
      />
      <Amenities
        bathroom={property.bathroom}
        kitchen={property.kitchen}
        backyard={property.backyard}
      />
      {/* <GeneralText
        title="Mortgage calculator"
        text="Ready to buy? Check out your estimated monthly costs with our easy-to-use mortgage calculator"
      /> */}
      <MortgageCalculator price={property.price} />
      <RequestForm address={property.address} />
      {/* <CarouselGeneral
        linkSource={'property'}
        title={'Related featured listings'}
        images={relatedProperty}
      /> */}
      {/* <ListingSignup /> */}
    </>
  );
}

export default FeaturedProperty;
