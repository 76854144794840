import React from 'react';
import { firestore } from '../../firebase.js';
import GeneralText from '../../Components/GeneralText/GeneralText';
import FeaturedRow from '../../Components/FeaturedRow/FeaturedRow';
import ListingSignup from '../../Components/ListingSignup/ListingSignup';
import heroImage from '../../Assets/images/hero_featuredlistings.png';
import './FeaturedListings.scss';

function FeaturedListings(){
  const [featListings, setFeatListings] = React.useState([]);

  React.useEffect(() => {
    getFeatListings();
  }, []);

  const getFeatListings = () => {
    firestore.collection('featuredproperty').get()
      .then(res => {
        let listings = [];
        for (let i = 0; i < res.docs.length; i++) {
          let listing = res.docs[i].data();
          listing.inverse = ((i + 1) % 2 === 0);
          listings.push(listing);
        }
        setFeatListings(listings);
      })
      .catch(err => {
        console.log(err);
      })
  };

  const renderListings = () => {
    let renderedListings = featListings.map(listing => {
      return (
        <FeaturedRow
          id={listing.id}
          image={listing.thumbnail}
          address={listing.address}
          price={listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          description={listing.description}
          inverse={listing.inverse}
        />
      );
    });
    return renderedListings;
  };

  return(
    <>
      <section className='featuredhero'>
        <img className='featuredhero__image' src={heroImage} alt="" />
      </section>
      <GeneralText
        title="Our featured listings"
        text="We take on listings we believe in. Check out the best listings in the Guelph area that are worth every penny of your investment. No such a thing as worrying about your next home. We bring you the home of your dreams for the right cost"
      />
      {renderListings()}
      <ListingSignup />
    </>
  )
}

export default FeaturedListings;