import { firestore } from "../../firebase.js";
import "./ListingSignup.scss";
import ConvertKitForm from "convertkit-react";
function ListingSignup() {
  const MY_FORM_ID = 4921911;
  return (
    <section className="listingsignup">
      <div className="listingsignup__textbox">
        <h3 className="listingsignup__title">
          Be the first one to see exclusive listings
        </h3>
        <h4 className="listingsignup__subtitle">
          Join our exclusive list today and get notifications on properties
          before they hit the market
        </h4>
      </div>

      <form
        className="listingsignup__form"
        target="_blank"
        action="https://formsubmit.co/8eea57a3985e2093d71cbff7c09f1d91"
        method="POST"
      >
        <input type="hidden" name="_subject" value="Newsletter Signup"></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input
          type="hidden"
          name="_next"
          value="https://www.rahmatyrealty.com/thankyou"
        ></input>
        <input
          type="text"
          className="listingsignup__input"
          name="Email"
          placeholder="Email"
        />
        <button type="submit" className="listingsignup__button">
          Submit
        </button>
      </form>
    </section>
  );
}

export default ListingSignup;
