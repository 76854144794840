import React from 'react';
import { firestore } from '../../firebase.js';
import SellersHero  from '../../Assets/images/sellers_hero.png';
import './sellers.scss';
import GeneralText from '../../Components/GeneralText/GeneralText';
import GuideCard from '../../Components/GuideCard/GuideCard';
import DownloadSellers from '../../Components/DownloadSellers/DownloadSellers';
import ListingSignup from '../../Components/ListingSignup/ListingSignup';
import imageOne from '../../Assets/icons/icn_sellers1.svg';
import imageTwo from '../../Assets/icons/icn_sellers2.svg';
import imageThree from '../../Assets/icons/icn_sellers3.svg';
import imageFour from '../../Assets/icons/icn_sellers4.svg';
import imageFive from '../../Assets/icons/icn_sellers5.svg';
import imageSix from '../../Assets/icons/icn_sellers6.svg';
import imageSeven from '../../Assets/icons/icn_sellers7.svg';
import imageEight from '../../Assets/icons/icn_sellers8.svg';
import imageNine from '../../Assets/icons/icn_sellers9.svg';
import imageTen from '../../Assets/icons/icn_sellers10.svg';

import ConsultationBS from '../../Assets/images/consultation_bs.png';

function Sellers(){
  const guideParts = [
    {
      title:"Decide to sell your home",
      text: "Make sure you are ready both financially and emotionally",
      img:imageOne,
      id: 1
    },
    {
      title:"Realtor consultation",
      text:"There is no commitment required on your part for the initial meeting with an agent. It will be educational and will help you identify the right agent for you. We will provide you with a comparative market analysis and all the tools they have to help sell your home the fastest",
      img: imageTwo,
      id: 2
    },
    {
      title:"Establish a price",
      text:"We will help you establish your asking price for your property",
      img: imageThree,
      id: 3
    },
    {
      title:"Prepare your home for sale",
      text:"View your home through the eyes of the buyer and ask yourself what you would expect. We will help guide you and give you tips on de-cluttering and other things that will help your home be more sellable",
      img: imageFour,
      id: 4
    },
    {
      title:"List it for sale",
      text:"When everything is in place, your agent will put your home on the open market. We will be actively working behind the scenes marketing your property to colleagues, clients and the public!",
      img: imageFive,
      id: 5
    },
    {
      title:"Showings",
      text:"Potential buyers may ask to see your home on short notice. It is best if you can accommodate these requests, you never want to miss a potential sale. After each showing, we will follow up with the people who viewed your home to hear their feedback",
      img: imageSix,
      id: 6
    },
    {
      title:"Offer negotiations",
      text:"If everything goes well, a buyer’s agent will present your agent with an offer. You have three choices—accept the offer, counter the offer or reject the offer. Our knowledge of your needs will enable your agent to represent you in the best way possible",
      img: imageSeven,
      id: 7
    },
    {
      title:"Under contract",
      text:"At this point, you have accepted an offer and have agreed to all the terms set forth in the contract",
      img: imageEight,
      id: 8
    },
    {
      title:"Conditional phase",
      text:"When the agreement of purchase and sale is accepted and signed by all parties, the conditional phase begins. The buyers will have a pre-determined amount of time to fulfill items likely including home inspection, financing, home insurance, etc. The date the conditions are removed, you now have a firm and binding contract for the sale of your home. SOLD!",
      img: imageNine,
      id: 9
    },
    {
      title:"Closing",
      text:"This is the date of transfer of funds and ownership that was agreed upon in your binding contract. Be sure you are packed up and ready to go before this date!",
      img: imageTen,
      id: 10
    },
  ];

  const renderGuide = (guides) => {
    let renderedGuides = guides.map(guide => {
      return (
        <GuideCard
          name={guide.title}
          text={guide.text}
          id={guide.id}
          img={guide.img}
        />
      )
    });
    return renderedGuides;
  }

  return(
    <>
      <section className='hero'>
        <img className='hero__image' src={SellersHero} alt="" />
      </section>
      <GeneralText
        title="Selling a home with Rahmaty Realty"
        text="Whether you have been on the selling side before or if it is your first time, selling your home can be a big decision. There are a lot of factors involved and we understand that it can be overwhelming. We are here to help you by making your experience as stress-free as possible with the best technology, advanced marketing strategies and experts to help you find the right buyer"
        row={true}
      />
      <DownloadSellers />
      <section className='guidebox'>
        <div className='guidebox__list'>
          {renderGuide(guideParts)}
        </div>
      </section>
      <ListingSignup />
    </>
  )
}

export default Sellers;