import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.scss';

function Sidebar() {
  return (
    <Menu right width={200}>
		<Link to='/about' className='sidebar__link'>Guelph</Link>
		<Link to='/sellersguide' className='sidebar__link'>Sellers guide</Link>
		<Link to='/buyersguide' className='sidebar__link'>Buyers guide</Link>
		<Link to='/homeevaluation' className='sidebar__link'>Home evaluation</Link>
		<Link to='/staging' className='sidebar__link'>Staging</Link>
		<Link to='/listings/guelph' className='sidebar__link'>MLS</Link>
		<Link to='/blog' className='sidebar__link'>Blog</Link>
    </Menu>
  )
}

export default Sidebar;