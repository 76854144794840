import React from "react";
import { firestore } from "../../firebase.js";
import axios from "axios";
import SelectBar from "../../Components/SelectBar/SelectBar";
import GeneralText from "../../Components/GeneralText/GeneralText";
import PropertyCard from "../../Components/PropertyCard/PropertyCard";
import CarouselGeneral from "../../Components/CarouselGeneral/CarouselGeneral";
import EmailSignup from "../../Components/EmailSignup/EmailSignup";
import FilterListing from "../../Components/FilterListing/FilterListing";
import placeholderProperty from "../../Assets/images/icn_noimage.svg";
import MLSListingHero from "../../Assets/images/MLSListingHero.png";

import "./MLSCaro.scss";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://rahmaty-server.vercel.app"
    : "https://rahmaty-server.vercel.app";
//'http://localhost:8080'

function MLSCaro() {
  const [currentCity, setCurrentCity] = React.useState({});
  const [currentProvince, setCurrentProvince] = React.useState("");
  const [localpicks, setLocalpicks] = React.useState([]);
  const [currentListings, setCurrentListings] = React.useState([]);
  const [pageNum, setPageNum] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [resultsPerPage, setResultsPerPage] = React.useState(3);
  const [sortBy, setSortBy] = React.useState("createdOnDesc");
  const [mlsNumber, setMlsNumber] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("all");
  const [beds, setBeds] = React.useState(0);
  const [baths, setBaths] = React.useState(0);
  const [priceRange, setPriceRange] = React.useState(["0", "5000000"]);
  const [sqRange, setSqRange] = React.useState(["0", "10000"]);

  React.useEffect(() => {
    getCityDetail("kw");
  }, ["kw", pageNum, resultsPerPage, sortBy]);

  const getCityDetail = (city) => {
    firestore
      .collection("city")
      .doc(city)
      .get()
      .then((res) => {
        let cityDetails = res.data();
        setCurrentCity(cityDetails);
        setCurrentProvince(cityDetails.province);
        firestore
          .collection("localpicks")
          .get()
          .then((res) => {
            let localPicks = [];
            res.docs.forEach((doc) => {
              if (
                cityDetails.province === "ON" &&
                doc.data().province === "ON"
              ) {
                localPicks.push({
                  image: doc.data().img,
                  link: doc.data().link,
                  name: doc.data().name,
                });
              } else if (
                cityDetails.province === "FL" &&
                doc.data().province === "FL"
              ) {
                localPicks.push({
                  image: doc.data().img,
                  link: doc.data().link,
                  name: doc.data().name,
                });
              }
            });
            setLocalpicks(localPicks);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    //IF REQUESTING ONTARIO LISTINGS
    getOnListings("kw");
    //IF REQUESTING FLORIDA LISTINGS
  };

  const getOnListings = (city) => {
    axios
      .get(`${API_URL}/city/${city}`, {
        params: {
          pageNum: pageNum,
          resultsPerPage: resultsPerPage,
          sortBy: sortBy,
          mlsNumber: mlsNumber,
          class: propertyType,
          beds: beds,
          baths: baths,
          price: priceRange,
          sqRange: sqRange,
        },
      })
      .then((res) => {
        setCurrentListings(res.data.listings);
        setTotalPages(res.data.numPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderPagination = () => {
    if (pageNum <= 3) {
      let i = 1;
      let pagination = [];
      while (i <= totalPages && i <= 7) {
        pagination.push(i);
        i++;
      }
      let renderedPagination = pagination.map((page) => {
        return (
          <button
            className={
              pageNum == page
                ? "city__pagebutton city__pagebutton--active"
                : "city__pagebutton"
            }
            onClick={() => setPageNum(page)}
          >
            {page}
          </button>
        );
      });
      if (pagination[pagination.length - 1] !== totalPages) {
        renderedPagination.push(
          <p className="city__pagebutton city__pagebutton--last">
            ...{totalPages}
          </p>
        );
      }
      return renderedPagination;
    } else if (pageNum > 3) {
      let i = pageNum - 3;
      let pagination = [];
      while (i <= totalPages && i <= pageNum + 3) {
        pagination.push(i);
        i++;
      }
      let renderedPagination = pagination.map((page) => {
        return (
          <button
            className={
              pageNum == page
                ? "city__pagebutton city__pagebutton--active"
                : "city__pagebutton"
            }
            onClick={() => setPageNum(page)}
            disabled={pageNum == page}
          >
            {page}
          </button>
        );
      });
      if (pagination[pagination.length - 1] !== totalPages) {
        renderedPagination.push(
          <p className="city__pagebutton city__pagebutton--last">
            ... {totalPages}
          </p>
        );
      }
      return renderedPagination;
    }
  };

  const renderPaginationMobile = () => {
    if (pageNum <= 3) {
      let i = 1;
      let pagination = [];
      while (i <= totalPages && i <= 3) {
        pagination.push(i);
        i++;
      }
      let renderedPagination = pagination.map((page) => {
        return (
          <button
            className={
              pageNum == page
                ? "city__pagebutton city__pagebutton--active"
                : "city__pagebutton"
            }
            onClick={() => setPageNum(page)}
          >
            {page}
          </button>
        );
      });
      if (pagination[pagination.length - 1] !== totalPages) {
        renderedPagination.push(
          <p className="city__pagebutton city__pagebutton--last">
            ...{totalPages}
          </p>
        );
      }
      return renderedPagination;
    } else if (pageNum > 3) {
      let i = pageNum - 1;
      let pagination = [];
      while (i <= totalPages && i <= pageNum + 1) {
        pagination.push(i);
        i++;
      }
      let renderedPagination = pagination.map((page) => {
        return (
          <button
            className={
              pageNum == page
                ? "city__pagebutton city__pagebutton--active"
                : "city__pagebutton"
            }
            onClick={() => setPageNum(page)}
            disabled={pageNum == page}
          >
            {page}
          </button>
        );
      });
      if (pagination[pagination.length - 1] !== totalPages) {
        renderedPagination.push(
          <p className="city__pagebutton city__pagebutton--last">
            ... {totalPages}
          </p>
        );
      }
      return renderedPagination;
    }
  };

  const resultsPerPageHandler = (num) => {
    setPageNum(1);
    setResultsPerPage(num);
  };

  const sortByHandler = (condition) => {
    setPageNum(1);
    setSortBy(condition);
  };

  const renderListings = () => {
    let renderedListings = currentListings.map((listing) => {
      let propertyPrice = listing.listPrice;
      propertyPrice.substr(1);
      propertyPrice = propertyPrice
        .split(".")[0]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let propertyName = listing.address.streetName;
      propertyName = propertyName.toLowerCase().split(" ");
      for (let i = 0; i < propertyName.length; i++) {
        propertyName[i] =
          propertyName[i].charAt(0).toUpperCase() +
          propertyName[i].substring(1);
      }
      propertyName = propertyName.join(" ");
      return (
        <PropertyCard
          link={listing.mlsNumber}
          image={
            listing.images.length > 0
              ? `https://cdn.repliers.io/${listing.images[0]}`
              : placeholderProperty
          }
          title={propertyName}
          text={propertyPrice}
          city={listing.address.city}
          beds={listing.details.numBedrooms}
          baths={listing.details.numBathrooms}
          sqfeet={listing.details.sqft}
          built={listing.details.yearBuilt}
        />
      );
    });
    return renderedListings;
  };

  const filterSubmitHandler = async (e) => {
    e.preventDefault();
    setPageNum(1);
    getOnListings("kw");
  };

  return (
    <>
      <div className="MLSCaro">
        <h2 className="MLSCaro__titles">Listings</h2>
      </div>

      <section className="city">
        <nav className="city__list">{renderListings()}</nav>
        <div className="city__paginationbox">
          <button
            className="city__previousbutton"
            onClick={() => setPageNum(pageNum - 1)}
            disabled={pageNum == 1}
          >
            &#60;
          </button>
          {renderPagination()}
          <button
            className="city__nextbutton"
            onClick={() => setPageNum(pageNum + 1)}
            disabled={pageNum == totalPages}
          >
            &#62;
          </button>
        </div>
        <div className="city__paginationmobile">
          <button
            className="city__previousbutton"
            onClick={() => setPageNum(pageNum - 1)}
            disabled={pageNum == 1}
          >
            &#60;
          </button>
          {renderPaginationMobile()}
          <button
            className="city__nextbutton"
            onClick={() => setPageNum(pageNum + 1)}
            disabled={pageNum == totalPages}
          >
            &#62;
          </button>
        </div>
      </section>
    </>
  );
}

export default MLSCaro;
