import { Link } from 'react-router-dom';
import icnFb from '../../Assets/icons/icn_fb.svg';
import icnIg from '../../Assets/icons/icn_ig.svg';
import icnLinkedin from '../../Assets/icons/icn_linkedin.svg';
import './Footer.scss';

const floridaURL = process.env.NODE_ENV === "production" ?
  'https://' :
  'http://localhost:3000/florida';

const ontarioURL = process.env.NODE_ENV === "production" ?
  'https://' :
  'http://localhost:3000/ontario';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__navbox'>
        <nav className='footer__nav'>
          {/* <div className='footer__navgroup footer__navgroup--desktop'>
            <Link to='/about' className='footer__link'>About Guelph</Link>
            <Link to='/homeevaluation' className='footer__link'>Home evaluation</Link>
            <Link to='/mls/kw' className='footer__link'>Properties</Link>
            <Link to='/blog' className='footer__link footer__link--desktop'>Blog</Link>
          </div> */}
          <div className='footer__navgroup footer__navgroup--mobile'>
            <h3 className='footer__link'>Contact</h3>
            <h4 className='footer__link contact'>info@rahmatyrealty.com</h4>
            <h5 className='footer__link contact'>(519)-242-5577</h5>
          </div>
{/*          <div className='footer__navgroup footer__navgroup--tablet'>
            <Link to='/featured' className='footer__link'>FEATURED LISTINGS</Link>
            <div className='footer__hiddensns'>
              <a
                className='footer__sns'
                href="https://www.facebook.com/jarealestategroup"
                target="_blank"
              >
                <img className='footer__snsimage' src={icnFb} alt="" />
              </a>
              <a
                className='footer__sns'
                href="https://www.instagram.com/officialjarealestategroup/"
                target="_blank"
              >
                <img className='footer__snsimage' src={icnIg} alt="" />
              </a>
              <a
                className='footer__sns'
                href="https://www.linkedin.com/company/ja-real-estate-group"
                target="_blank"
              >
                <img className='footer__snsimage' src={icnLinkedin} alt="" />
              </a>
            </div>
          </div>*/}
          <div className='footer__snsgroup'>
            <a
              className='footer__sns'
              href="https://www.facebook.com/Shikeeb.realestate/"
              target="_blank"
            >
              <img className='footer__snsimage' src={icnFb} alt="" />
            </a>
            <a
              className='footer__sns footer__sns--ig'
              href="https://www.instagram.com/rahmatyrealty/?hl=en"
              target="_blank"
            >
              <img className='footer__snsimage' src={icnIg} alt="" />
            </a>
            <a
              className='footer__sns'
              href="https://www.linkedin.com/"
              target="_blank"
            >
              <img className='footer__snsimage' src={icnLinkedin} alt="" />
            </a>
          </div>
        </nav>
      </div>
      <div className='footer__bar'>
        <p className='footer__bartext'>© 2021 by Rahmaty Realty. All rights reserved. Developed by Atreo Media.</p>
      </div>
    </footer>
  );
}

export default Footer;