import React from 'react';
import './CityCard.scss';

function CityCard(props) {
    return (
        <>
            <div className=
                {
                    props.id % 2 != 0 ?
                        'cityCard' :
                        'cityCard rowReverse'
                }
            >
                <div className="cityCard__textCont">
                    <h2 className="cityCard__title">{props.name}</h2>
                    <h3 className="cityCard__subtext" dangerouslySetInnerHTML={{ __html: props.text }}></h3>
                </div>
                <div className="cityCard__imageCont">
                    <img className="cityCard__image" src={props.img} />
                </div>
            </div>
        </>
    );
}

export default CityCard